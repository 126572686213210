import React, { useState } from "react";
import logo from './logo.svg';
import './App.scss';

// import Navbar from '../components/navbar/Navbar';
import { BottomNavigation } from "reactjs-bottom-navigation";
import { Routes, Route, useRoutes } from "react-router-dom";
import routes from './router/router';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PieChartOutlined,
  DotChartOutlined,
  FundViewOutlined,
  BoxPlotOutlined,
} from '@ant-design/icons';

import {
  ConfigProvider,
  theme,
} from 'antd';

const App = () => {
  const content = useRoutes(routes);

  return (
    <ConfigProvider theme={{
      // 1. Use dark algorithm
      algorithm: theme.compactAlgorithm,

      // 2. Combine dark algorithm and compact algorithm
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
    }}>
      {content}
    </ConfigProvider>
  );
}

export default App;
