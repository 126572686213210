import React, { useContext, useState } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import { PieChartOutlined, DotChartOutlined, BoxPlotOutlined, FundOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { BottomNavigation } from 'reactjs-bottom-navigation';
import { Navigate } from 'react-router';
import { MainAppContext } from '../../../contexts/MainAppContext';

const { Footer } = Layout;

export default function CustomBottomNavigation() {
    const navigate = useNavigate();
    const location = useLocation();

    // Map route paths to their corresponding index in the navigation items array
    const routeToIndex = {
        '/trading/spot': 0,
        '/trading/margin': 1,
        '/trading/derivatives': 2,
        '/trading/scanner': 3,
    };

    // Get the selected index based on the current route
    const selectedIndex = routeToIndex[location.pathname] || 0;

    const bottomNavItems = [
        {
            title: "Spot",
            type: "spot",
            onClick: ({ id }) => navigate(`/trading/spot`),
            icon: <PieChartOutlined />, // just for example
            activeIcon: <PieChartOutlined color="#fff" />
        },
        {
            title: "Margin",
            type: "margin",
            onClick: ({ id }) => navigate(`/trading/margin`),
            icon: <DotChartOutlined />, // just for example
            activeIcon: <DotChartOutlined color="#fff" />
        },
        {
            title: "Derivatives",
            type: "derivatives",
            onClick: ({ id }) => navigate(`/trading/derivatives`),
            icon: <BoxPlotOutlined />, // just for example
            activeIcon: <BoxPlotOutlined color="#fff" />
        },
        // the render method enables custom item content
        {
            title: "Scanner",
            type: "scanner",
            onClick: ({ id }) => navigate(`/trading/scanner`),
            icon: <FundOutlined />, // just for example
            activeIcon: <FundOutlined color="#fff" />,
        },
    ];

    return (
        <Footer>
            <BottomNavigation
                items={bottomNavItems}
                selected={selectedIndex}
                activeBgColor="white"
                activeTextColor="#73d13d"
            />
        </Footer>

    );
}
