import createOperation from '../createOperation';
import slice from './slice';
import authService from '../../services/auth.service';
import usersService from '../../services/users.service';

const { actions } = slice;

export const login = createOperation({
  actions: {
    successAction: actions.loginFulfilled,
    failedAction: actions.loginRejected
  },
  async process({ payload: data }) {
    const params = data
    return await authService.login(params);
  },
  successMessage: 'loginSuccessfull',
  errorMessage: 'loginFailed'
});

export const logout = createOperation({
  actions: {
    successAction: actions.logoutFulfilled
  },
  async process() {
    return await authService.logout();
  },
});

export const loadUserInfo = createOperation({
  actions: {
    successAction: actions.loadUserInfoSuccess
  },
  async process() {
    return await usersService.loadUserInfo();
  },
});
