import { Suspense, lazy } from 'react';

import SidebarLayout from '../layouts/SidebarLayout';
import BaseLayout from '../layouts/BaseLayout';

import SuspenseLoader from '../components/SuspenseLoader';

import { Navigate } from 'react-router';
import Spot from '../content/pages/Trading/Spot';
import Margin from '../content/pages/Trading/Margin';
import Derivatives from '../content/pages/Trading/Derivatives';
import Scanner from '../content/pages/Trading/Scanner';

const Loader = (Component: any) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

//Auth
const Login = Loader(lazy(() => import('../content/pages/Login')));
// const LoginAuth = Loader(lazy(() => import('src/content/pages/Login'))); // use for SSO Login

// Pages
// const Permission = Loader(lazy(() => import('src/content/pages/Permission')));
// const Role = Loader(lazy(() => import('src/content/pages/Roles')));
// const User = Loader(lazy(() => import('src/content/pages/User')));
// const AttachPer = Loader(lazy(() => import('src/content/pages/Roles/attachPer/Index')));
// Status
const Status404 = Loader(lazy(() => import('../content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('../content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('../content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('../content/pages/Status/Maintenance')));

const routes = [
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '404',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      },
      // {
      //   path: '*',
      //   element: <Navigate to={"/admin"} />
      // },
      {
        path: '',
        element: <Navigate to={"/trading/spot"} />
      },
    ]
  },

  {
    path: 'login',
    element: (
      <BaseLayout />
    ),
    children: [
      {
        path: '',
        element: <Login />
      },
    ]
  },
  {
    path: 'trading',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'spot',
        element: <Spot />
      },
      {
        path: 'margin',
        element: <Margin />
      },
      {
        path: 'derivatives',
        element: <Derivatives />
      },
      {
        path: 'scanner',
        element: <Scanner />
      }
    ]
  },
];

export default routes;
