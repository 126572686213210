import { connect } from 'react-redux';
import { actions, selectors } from '../../../../../store/trading/derivatives';
import ModalContent from '../../../../modal/CreateFollowPairModal/Content';

const mapStateToProps = (state: any) => ({
    unFollowPairs: selectors.unFollowPairsSelector(state),
    isModalShowed: selectors.isModalShowedSelector(state),
    followPairInfor: selectors.followPairInforSelector(state),
});
const mapDispatchToProps = dispatch => ({
    createFollowPair: async (values: any) => {
        const result = await dispatch(actions.createFollowPair(values));
        // After createFollowPair success, dispatch listFollowPairs
        await dispatch(actions.listFollowPairs({ type: 'derivatives' }));
        return result;
    },
    toggleConfigModal: (values: any) => {
        return dispatch(actions.toogleConfigModal(values));
    }
});



export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
