import React, { useState } from "react";
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import { GuardAuth } from '../../router/guard';
import { RouteConfigs } from '../../router/route.config';
// import { BottomNavigation } from "reactjs-bottom-navigation";
import CustomBottomNavigation from "./CustomBottomNavigation";

import { Layout } from 'antd';

const { Header, Footer, Sider, Content } = Layout;

interface SidebarLayoutProps {
    children?: ReactNode;
}


const SidebarLayout: FC<SidebarLayoutProps> = () => {
    // const isOpenSidebar: boolean = useSelector(selectorApp.sideBarSelector);
    return (
        <>
            <GuardAuth settings={RouteConfigs}>
                <Layout>
                    <Header style={{ padding: 0, position: "fixed", width: "100%", zIndex: 100 }}>
                        <Navbar />
                    </Header>
                    <Content style={{ padding: "70px 0px 0px 0px" }}>
                        <Outlet />
                    </Content>
                    <Footer>
                        <CustomBottomNavigation />
                    </Footer>
                </Layout>
            </GuardAuth >
        </>
    );
};

export default SidebarLayout;