import React, { useEffect, useState } from "react";

const Scanner = (props: any) => {
    return (
        <div>Scanner</div>
    );
};

export default Scanner

