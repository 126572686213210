import { combineReducers, Middleware } from 'redux';
import { thunk } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';

import { reducer as authReducer } from './auth';
import { reducer as spotReducer } from './trading/spot';
import { reducer as marginReducer } from './trading/margin';
import { reducer as derivativesReducer } from './trading/derivatives';


// gộp các reducer
const reducer = combineReducers({
    auth: authReducer,
    spotTrading: spotReducer,
    marginTrading: marginReducer,
    derivativesTrading: derivativesReducer,
});

const middleware = process.env.NODE_ENV !== 'production' ? [thunk] : [thunk];

const store = configureStore({
    reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware)
});

export default store;   