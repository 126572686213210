import { createTradingFeature, createTradingOperation } from '../utilities';

const {
  reducer,
  actions: featureActions,
  followPairsSelector,
  unFollowPairsSelector,
  isModalShowedSelector,
  followPairInforSelector
} = createTradingFeature('marginTrading');

const operations = createTradingOperation('marginTrading');

export { reducer };

export const actions = {
  ...operations,
  ...featureActions,
};

export const selectors = {
  followPairsSelector,
  unFollowPairsSelector,
  isModalShowedSelector,
  followPairInforSelector
}