import axios from './abstract.service';

export const loadUserInfo = (): any => {
    return axios.get('users/info', {  }).then(res => res);
}

const usersService = {
    loadUserInfo
};

export default usersService;
