/**
 * description: This file defines the common constants used in the application 
 * CONFIG_APP, LANGS, STATE_APP
 * TYPE_LOCAL_STORAGE, RESTART_POLICY
 * DEVICE_STATUS_MESSAGE, REQUEST_MESSAGE
 * KEY_DEPLOY_STATUS, VIEW_DELOY_STATUS
 * LOCAL_STORAGE_KEY
 */

export const CONFIG_APP = {
    API_URL: process.env.REACT_APP_API_BASE_URL,
    CURRENT_STATE_APP: process.env.REACT_APP_STATE,
    GRAFANA_URL: process.env.REACT_APP_GRAFANA_API_URL,
    ORG_ID: process.env.REACT_APP_GRAFANA_API_ORG_ID,
    RESOURCE: process.env.REACT_APP_GRAFANA_API_VAR_RESOURCE,
    PANNEL_ID: process.env.REACT_APP_GRAFANA_API_PANNEL_ID,
    CONNECTION_STRING_TO_IOTHUB: process.env.REACT_APP_CONNECTION_STRING,
    SUBCRIPTION_ID: process.env.REACT_APP_SUBCRIPTION_ID
};

export const LANGS = [
    {
        value: 'vi',
        name: 'common.vietnamese'
    },
    {
        value: 'en',
        name: 'common.english'
    }
];

export const BREAK_POINT_SCREEN = 1279

export const STATE_APP: { [key: string]: string } = {
    MAINTAIN: 'maintain',
    RUNNING: 'running',
};

export const TYPE_LOCAL_STORAGE: { [key: string]: string } = {
    URL_REDIRECT: 'url-redirect',
};

export const REQUEST_MESSAGE: { [key: string]: string } = {
    'ExpiredAuthenticationToken': 'Your working session expired, please login again',
    'InvalidAuthenticationToken': 'Your working session invalid, please login again',
    'TooManyDevicesError': 'Your usage quota exceeded the allocated quota. Please select another Hub by clicking your account name at top-left of the screen, otherwise contact Panasonic for support'
};

export const LOCAL_STORAGE_KEY: { [key: string]: string } = {
    APP_THEME: 'appTheme',
    ACCESS_TOKEN_APP: 'accessToken',
    ACCESS_TOKEN_REGISTRY: 'accessTokenRegistry',
    ACCOUNT_INFO: 'info',
    CURRENT_HUB: 'currentHub',
    HUB_INFO: 'hubInfo',
    HUB_KEY_NAME: 'keyName',
    HUB_PRIMARY_KEY: 'primaryKey',
    DEVICE_DATA: 'deviceData',
    MODULE_DATA: 'moduleData'
}