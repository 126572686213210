// singin, singout, singup
import axios from './abstract.service';
import { CONFIG_APP, LOCAL_STORAGE_KEY } from "../constants/common";

const API_URL = CONFIG_APP.API_URL;

const register = (username: string, email: string, password: string) => {
    return axios.post(`${API_URL}login`, {
        username,
        email,
        password,
    });
};

const login = (params: any): any => {
    return axios.post('/login', params).then((response: any) => {
        if (response.data && response.data.access_token) {
            localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP, response.data.access_token);
        }

        return response.data;
    }).catch(err => {
        return Promise.reject(err);
    });
};

const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP);
};

const authService = {
    register,
    login,
    logout,
};

export default authService;