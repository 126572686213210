import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { CONFIG_APP } from "../constants/common";
import { selectors as selectorAuth } from '../store/auth';

export const GuardAuth = ({ children, settings }): JSX.Element => {
    //check authen, author
    // if not authen => login page
    // if not author => 404 page
    const isLoggedIn = useSelector(selectorAuth.userSelector);
    // const isAuthor = null;
    const location = useLocation();

    let redriect: JSX.Element = <Navigate to="/404" replace state={{ path: location.pathname }} />

    switch (CONFIG_APP.CURRENT_STATE_APP) {
        case 'maintain':
            redriect = <Navigate to={settings.maintenance.defaultRoute} replace state={{ path: location.pathname }} />
            break;
        case 'comingson':
            redriect = <Navigate to={settings.comingson.defaultRoute} replace state={{ path: location.pathname }} />
            break;
        case 'running':
            if (isLoggedIn) {
                redriect = children
                // redriect = author == 'a' ? children : <Navigate to={settings.unauthorization.defaultRoute} replace state={{ path: location.pathname }} />
            }
            else {
                redriect = <Navigate to="/login" replace state={{ path: location.pathname }} />
            }
            break;
    }

    return redriect;
}