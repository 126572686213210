import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEY } from "../../constants/common";

const auth = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN_APP);
const info = localStorage.getItem(LOCAL_STORAGE_KEY.ACCOUNT_INFO);

const initialState = {
    isLoggedIn: auth  ? true : false,
    accountInfo: {},
    isError: false,
    message: 'The application is fetching data, please wait a few minutes....'
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loginFulfilled: (state, action) => ({
            ...state,
            isLoggedIn: true,
            // accountInfo: action.payload.result,
            message: ""
        }),
        loginRejected: (state, action) => ({
            ...state,
            isLoggedIn: false,
            isError: true,
            accountInfo: {},
            message: action.payload || 'An error occurred while retrieving data, please contact the administrator'
        }),
        logoutFulfilled: (state, action) => ({
            ...state,
            isLoggedIn: false,
        }),
        loadUserInfoSuccess:(state, action) => {
            console.log(action.payload.result.data);
            return {
                ...state,
                accountInfo: action.payload.result.data,
            };
        },
        clearState: (state) => ({
            ...state,
            isLoggedIn: false,
            isError: false,
            accountInfo: {},
            message: ""
        })
    }
});

export default authSlice;
