import { memo } from 'react';
import { Main } from './main/Loadable';

import InfoModal from './main/InfoModal';
const Margin = memo(() => {
    return (
        <>
            <Main />
            <InfoModal />
        </>
    )
})

export default Margin
