import { createSlice, createSelector } from "@reduxjs/toolkit";
import createOperation from '../createOperation';
import * as followPairsService from '../../services/followPairs.service';
import * as pairsService from '../../services/pairs.service';
import {actions} from "./spot";

export const createTradingFeature = (featureName) => {
    const initialState = {
        followPairs: [],
        unFollowPairs: [],
        followPairInfor: {},
        isModalShowed: false,
    };

    const slice = createSlice({
        name: featureName,
        initialState,
        reducers: {
            toogleConfigModal: (state, action) => {
                return {
                    ...state,
                    isModalShowed: !state.isModalShowed,
                    followPairInfor: action.payload
                };
            },
            setFollowPairInfo: (state, action) => {
                return {
                    ...state,
                    followPairInfor: action.payload.result,
                };
            },
            listFollowPairsSuccess: (state, action) => {
                return {
                    ...state,
                    followPairs: action.payload.result.data,
                };
            },
            listUnFollowPairsSuccess: (state, action) => {
                return {
                    ...state,
                    unFollowPairs: action.payload.result.data,
                };
            },
            createFollowPairSuccess: (state, action) => {
                const newData = action.payload.result.data;

                const existingItemIndex = state.followPairs.findIndex(
                    (item) => item.id === newData.id
                );

                if (existingItemIndex !== -1) {
                    const updatedFollowPairs = [...state.followPairs];
                    updatedFollowPairs[existingItemIndex] = newData;

                    return {
                        ...state,
                        followPairs: updatedFollowPairs,
                    };
                } else {
                    return {
                        ...state,
                        followPairs: [...state.followPairs, newData],
                    };
                }
            },
            getFollowPairByIdSuccess: (state, action) => ({
                ...state,
                followPairInfo: action.payload.result.data,
            }),
            updateFollowPairStatusStart: (state, action) => {
                const { followPairId, status } = action.payload;
                const updatedFollowPairs = state.followPairs.map((followPair) => {
                    if (followPair.id === followPairId) {
                        return {
                            ...followPair,
                            status,
                        };
                    }
                    return followPair;
                });
                return {
                    ...state,
                    followPairs: updatedFollowPairs,
                };
            },
            updateFollowPairStatusSuccess: (state, action) => {
                const { id, status } = action.payload.result.data;
                const updatedFollowPairs = state.followPairs.map((followPair) => {
                    if (followPair.id === id) {
                        return {
                            ...followPair,
                            status,
                        };
                    }
                    return followPair;
                });

                return {
                    ...state,
                    followPairs: updatedFollowPairs,
                };
            },
            updateFollowPairAutoEnableStart: (state, action) => {
                const { followPairId, auto_enable } = action.payload;
                const updatedFollowPairs = state.followPairs.map((followPair) => {
                    if (followPair.id === followPairId) {
                        return {
                            ...followPair,
                            auto_enable,
                        };
                    }
                    return followPair;
                });
                return {
                    ...state,
                    followPairs: updatedFollowPairs,
                };
            },
            updateFollowPairAutoEnableSuccess: (state, action) => {
                const { id, auto_enable } = action.payload.result.data;
                const updatedFollowPairs = state.followPairs.map((followPair) => {
                    if (followPair.id === id) {
                        return {
                            ...followPair,
                            auto_enable,
                        };
                    }
                    return followPair;
                });

                return {
                    ...state,
                    followPairs: updatedFollowPairs,
                };
            },
        },
    });

    const featureSelector = (state) => {
        return state[featureName];
    };

    const followPairsSelector = createSelector(
        [featureSelector],
        (featureState) => featureState.followPairs
    );

    const unFollowPairsSelector = createSelector(
        [featureSelector],
        (featureState) => featureState.unFollowPairs
    );
    const isModalShowedSelector = createSelector(
        [featureSelector],
        (featureState) => featureState.isModalShowed
    );
    const followPairInforSelector = createSelector(
        [featureSelector],
        (featureState) => featureState.followPairInfor
    );

    return {
        ...slice,
        featureSelector,
        followPairsSelector,
        unFollowPairsSelector,
        isModalShowedSelector,
        followPairInforSelector
    };
};

export const createTradingOperation = (featureName) => {

    const featureActions = createTradingFeature(featureName).actions;

    const listFollowPairs = createOperation({
        actions: {
            successAction: featureActions.listFollowPairsSuccess,
        },
        async process(params: any) {
            return await followPairsService.GetListFollowPairs(params?.payload);
        },
    });

    const listUnFollowPairs = createOperation({
        actions: {
            successAction: featureActions.listUnFollowPairsSuccess,
        },
        async process(params: any) {
            return await pairsService.GetListUnfollowPairs(params?.payload);
        },
    });
    const createFollowPair = createOperation({
        actions: {
            successAction: featureActions.createFollowPairSuccess,
        },
        async process(params: any) {
            return await followPairsService.createFollowPair(params?.payload);
        },
    });

    const updateFollowPairStatus = createOperation({
        actions: {
            startAction: featureActions.updateFollowPairStatusStart,
            successAction: featureActions.updateFollowPairStatusSuccess,
        },
        async process(params: any) {
            return await followPairsService.updateFollowPairStatus(params?.payload);
        },
    });

    const updateFollowPairAutoEnable = createOperation({
        actions: {
            startAction: featureActions.updateFollowPairAutoEnableStart,
            successAction: featureActions.updateFollowPairAutoEnableSuccess,
        },
        async process(params: any) {
            return await followPairsService.updateFollowPairAutoEnable(params?.payload);
        },
    });

    const operators = {
        listFollowPairs,
        listUnFollowPairs,
        createFollowPair,
        updateFollowPairStatus,
        updateFollowPairAutoEnable
    }

    return operators;
};
