import { useState, createContext } from 'react';
type MainAppContext = {
    isSidebarShowed: boolean; toggleSidebar: () => void;
};

export const MainAppContext = createContext<MainAppContext>(
    {} as MainAppContext
);

export const MainAppContextProvider = props => {
    const { children } = props;

    const [isSidebarShowed, setSidebarToggle] = useState(false);

    const toggleSidebar = () => {
        setSidebarToggle(!isSidebarShowed);
    };


    return (
        <MainAppContext.Provider value={{ isSidebarShowed, toggleSidebar }}>
            {children}
        </MainAppContext.Provider>
    );
};
