import { useState, useEffect } from "react";
import { Button, Input, Modal } from 'antd';
import { Form, Select, Row, Col, Switch, Slider } from "antd";

const { Option } = Select;

const ModalContent = ({ unFollowPairs, createFollowPair, isModalShowed, toggleConfigModal, followPairInfor }) => {
    const [form] = Form.useForm();
    const [selectedPair, setSelectedPair] = useState(null);

    const onSubmit = (values: any) => {
        createFollowPair(values);
        toggleConfigModal({})
    }
    useEffect(() => {
        if (followPairInfor) {
            form.setFieldsValue({
                symbol: followPairInfor.symbol,
                selectedRowKeys: followPairInfor.selectedRowKeys,
                type: followPairInfor.type,
                pos_side: followPairInfor.pos_side,
                entry_percent: followPairInfor.entry_percent,
                amount: followPairInfor.amount,
                auto_increment_amount_percent: followPairInfor.auto_increment_amount_percent,
                order_time_limit: followPairInfor.order_time_limit,
                max_order_amount: followPairInfor.max_order_amount,
                auto_increment_entry_percent: followPairInfor.auto_increment_entry_percent,
                auto_reduce_tp_percent: followPairInfor.auto_reduce_tp_percent,
                tp_percent: followPairInfor.tp_percent,
                sl_percent: followPairInfor.sl_percent,
                status: followPairInfor.status,
                is_adaptive: followPairInfor.is_adaptive,
                is_reverse: followPairInfor.is_reverse,
                enable_safe_mode:followPairInfor.enable_safe_mode,
                auto_enable:followPairInfor.auto_enable,
                auto_oc:followPairInfor.auto_oc,
                boll_mode:followPairInfor.boll_mode,
                margin_leverage_ratio: followPairInfor.margin_leverage_ratio > 0 ? followPairInfor.margin_leverage_ratio : 1,
            });
            const selected = unFollowPairs.find(pair => pair.symbol === followPairInfor.symbol);
            setSelectedPair(selected);
        }

    }, [followPairInfor])



    return (
        <Modal
            centered
            title="CREATE TRADE CONFIGS"
            open={isModalShowed}
            onCancel={() => toggleConfigModal({})}
            footer={[
                null
            ]}
        >
            <Form
                form={form}
                size="large"
                name="folowpair_config_form"
                layout="vertical"
                onFinish={onSubmit}
            >
                {(!followPairInfor.selectedRowKeys || followPairInfor.selectedRowKeys.length === 0) && (
                    <Form.Item name="symbol" label="Choose a symbol *">
                        <Select
                            showSearch
                            placeholder="Choose a symbol"
                            style={{ width: "100%" }}
                            onChange={(value) => {
                                const selected = unFollowPairs.find(pair => pair.symbol === value);
                                setSelectedPair(selected);
                            }}
                        >
                            {unFollowPairs.map(pair => (
                                <Option key={pair.symbol} value={pair.symbol}>
                                    {pair.symbol}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                {(followPairInfor.selectedRowKeys && followPairInfor.selectedRowKeys.length > 0) && (
                    <Form.Item name="selectedRowKeys" hidden={true} label="FollowPairIds">
                        <Input type='text' placeholder="Enter OC" />
                    </Form.Item>
                )}
                <Form.Item name="type" hidden={true}>
                    <Input />
                </Form.Item>


                <Form.Item name="pos_side">
                    <Select disabled={followPairInfor && (followPairInfor.symbol || followPairInfor.selectedRowKeys)}
                        showSearch
                        placeholder="Choose a post side"
                        style={{ width: "100%" }}
                    >
                        <Option key='long' value='long'>
                            LONG
                        </Option>
                        {(followPairInfor.type != 'spot' ) && (
                            <Option key='short' value='short'>
                                SHORT
                            </Option>
                            )}

                    </Select>
                </Form.Item>

                <Form.Item name="entry_percent" label="OC"
                    rules={[
                        {
                            pattern: /^-?\d+(\.\d{1,2})?$/,
                            message: 'Please enter a valid OC value with up to 2 decimal places.',
                        },
                    ]}
                >
                    <Input type='number' step={0.01} placeholder="Enter OC" />
                </Form.Item>

                <Form.Item name="amount" label="Amounts">
                    <Input type='number' placeholder="Enter amounts" />
                </Form.Item>
                <div style={{ margin: "-15px 0px 0px 15px", fontSize: "xx-small", fontWeight: 700, color: "#b46868" }}>
                    Min sz: {selectedPair ? selectedPair.minSz : 0} {selectedPair ? selectedPair.asset : ""} ~ {selectedPair ? Math.ceil(selectedPair.minSz * selectedPair.close * 1.1) : 0} USDT
                </div>

                <Form.Item name="auto_increment_amount_percent" label="Auto amount percent"
                    rules={[
                        {
                            pattern: /^-?\d+(\.\d{1,2})?$/,
                            message: 'Please enter a valid Reduce value with up to 2 decimal places.',
                        },
                    ]}
                >
                    <Input type='number' placeholder="Enter auto amounts percent" />
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item name="order_time_limit" label="Amount expire">
                            <Input type='number' placeholder="Enter amount expire" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="max_order_amount" label="Limit">
                            <Input type='number' placeholder="Enter limit" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="auto_increment_entry_percent" label="Auto increase OC"
                    rules={[
                        {
                            pattern: /^-?\d+(\.\d{1,2})?$/,
                            message: 'Please enter a valid Auto increase OC value with up to 2 decimal places.',
                        },
                    ]}
                >
                    <Input type='number' placeholder="Enter auto increase OC" />
                </Form.Item>

                <Form.Item name="auto_reduce_tp_percent" label="Reduce"
                    rules={[
                        {
                            pattern: /^-?\d+(\.\d{1,2})?$/,
                            message: 'Please enter a valid Reduce value with up to 2 decimal places.',
                        },
                    ]}
                >
                    <Input type='number' placeholder="Enter reduce" />
                </Form.Item>

                <Form.Item name="tp_percent" label="Take profit"
                    rules={[
                        {
                            pattern: /^-?\d+(\.\d{1,2})?$/,
                            message: 'Please enter a valid Reduce value with up to 2 decimal places.',
                        },
                    ]}
                >
                    <Input type='number' placeholder="Enter take profit" />
                </Form.Item>

                <Form.Item name="sl_percent" label="Stop loss"
                           rules={[
                               {
                                   pattern: /^-?\d+(\.\d{1,2})?$/,
                                   message: 'Please enter a valid Reduce value with up to 2 decimal places.',
                               },
                           ]}
                >
                    <Input type='number' placeholder="Enter take loss" />
                </Form.Item>

                <Row gutter={24}>
                    <Col span={0}>
                        <Row style={{ alignItems: 'baseline' }} >
                            <Form.Item name="is_reverse" valuePropName="checked">
                                <Switch size="small" />

                            </Form.Item>
                            <span style={{ marginLeft: '5px' }}>Reverse</span>
                        </Row>
                    </Col>

                    <Col span={0}>
                        <Row style={{ alignItems: 'baseline' }} hidden={true}>
                            <Form.Item name="is_adaptive" valuePropName="checked">
                                <Switch size="small" />

                            </Form.Item>
                            <span style={{ marginLeft: '5px' }}>Adaptive</span>
                        </Row>

                    </Col>
                    {(!followPairInfor.selectedRowKeys || followPairInfor.selectedRowKeys.length === 0) && (
                        <Col span={8}>
                            <Row style={{ alignItems: 'baseline' }}>
                                <Form.Item name="status" valuePropName="checked">
                                    <Switch size="small" />

                                </Form.Item>
                                <span style={{ marginLeft: '5px' }}>Status</span>
                            </Row>
                        </Col>
                    )}


                    <Col span={8}>
                        <Row style={{ alignItems: 'baseline' }}>
                            <Form.Item name="enable_safe_mode" valuePropName="checked">
                                <Switch size="small" />

                            </Form.Item>
                            <span style={{ marginLeft: '4px' }}>Safe</span>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row style={{ alignItems: 'baseline' }}>
                            <Form.Item name="auto_enable" valuePropName="checked">
                                <Switch size="small" />

                            </Form.Item>
                            <span style={{ marginLeft: '4px' }}>A-Enable</span>
                        </Row>
                    </Col>

                    {/*<Col span={8}>*/}
                    {/*    <Row style={{ alignItems: 'baseline' }}>*/}
                    {/*        <Form.Item name="auto_oc" valuePropName="checked">*/}
                    {/*            <Switch size="small" />*/}

                    {/*        </Form.Item>*/}
                    {/*        <span style={{ marginLeft: '4px' }}>A-OC</span>*/}
                    {/*    </Row>*/}
                    {/*</Col>*/}

                    <Col span={8}>
                        <Row style={{ alignItems: 'baseline' }}>
                            <Form.Item name="boll_mode" valuePropName="checked">
                                <Switch size="small" />

                            </Form.Item>
                            <span style={{ marginLeft: '4px' }}>Boll</span>
                        </Row>
                    </Col>
                </Row>


                {followPairInfor.type === 'margin' && (
                    <>
                        {/* <div className='text-filled-red'>$42,005.00</div>
                        <div style={{ fontWeight: 'bold' }}>Total: $42,005.00 USDT</div>
                        <div>Borow: $42,005.00 AAVE</div>
                        <div style={{ fontWeight: 'bold' }}>Max: $42,005.00 AAVE</div> */}

                        <Form.Item name="margin_leverage_ratio">
                            <Slider
                                marks={{
                                    1: '1X',
                                    2: '2X',
                                    3: '3x',
                                    4: '4X',
                                    5: '5X',
                                    10: '10x'
                                }}
                                min={1}
                                max={selectedPair == null || selectedPair.lever > 5 ? 5 : selectedPair.lever}
                            />
                        </Form.Item>
                    </>
                )}
                {followPairInfor.type === 'derivatives' && (
                    <>
                        {/* <div className='text-filled-red'>$42,005.00</div>
                        <div style={{ fontWeight: 'bold' }}>Total: $42,005.00 USDT</div>
                        <div>Borow: $42,005.00 AAVE</div>
                        <div style={{ fontWeight: 'bold' }}>Max: $42,005.00 AAVE</div> */}

                        <Form.Item name="margin_leverage_ratio">
                            <Slider
                                marks={{
                                    1: '1X',
                                    5: '5X',
                                    10: '10x',
                                    15: '15X',
                                    20: '20X',
                                }}
                                min={1}
                                max={20}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <Button style={{ marginRight: '10px' }}
                        onClick={() => toggleConfigModal({})}>
                        CANCEL
                    </Button>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        {(followPairInfor && (followPairInfor.symbol || followPairInfor.selectedRowKeys)) ? 'UPDATE' : 'CREATE'}
                    </Button>

                </Form.Item>
            </Form>
        </Modal >
    );
}

export default ModalContent;
