import React, {useState, useContext, useEffect} from "react";
import { Layout, Button, Drawer, Space, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import './Navbar.scss';
import {useDispatch, useSelector} from 'react-redux';
import { actions, selectors } from '../../../store/auth';
import { MainAppContext } from "../../../contexts/MainAppContext";

const { Text, Title } = Typography;

const Navbar = () => {
    const { isSidebarShowed, toggleSidebar } = useContext(MainAppContext);

    const dispatch = useDispatch();
    const accountInfo:any = useSelector(selectors.accountInfoSelector);
    useEffect(() => {
        console.log('load user info');
        dispatch<any>(actions.loadUserInfo({}))
    }, []);
    useEffect(() => {
        console.log(accountInfo);
        document.title = accountInfo?.name;
    }, [accountInfo]);

    const drawerSize = 'default';
    return (
        <nav className="navbar">
            <Layout>
                <Layout.Header className="nav-header">
                    <div className="nav-container-between">
                        <div className="nav-container-between">
                            <Button className="menuButton" onClick={toggleSidebar}>
                                <MenuOutlined />
                            </Button>
                            <Space direction="vertical" className="nav-header-item">
                                <Text strong className="nav-text">${ (accountInfo?.current_balance ?? 0).toFixed(2) } (${ (accountInfo?.current_usdt_total ?? 0).toFixed(2)})</Text>
                                <Title level={5} className="nav-text">BALANCE</Title>
                            </Space>

                            <Space direction="vertical" className="nav-header-item">
                                <Text strong className="nav-text">${ (accountInfo?.current_usdt_free ?? 0).toFixed(2)}</Text>
                                <Title level={5} className="nav-text">AVAILABLE</Title>
                            </Space>
                        </div>

                        <div>
                            Score
                        </div>
                    </div>

                    <div className="navbar-menu">
                        <Drawer
                            title={accountInfo?.name}
                            placement="left"
                            closable={true}
                            onClose={toggleSidebar}
                            width={"75%"}
                            visible={isSidebarShowed}
                            style={{ zIndex: 99999 }}

                            footer={
                                <div style={{ textAlign: 'center' }}>
                                    <Button type="primary"
                                        onClick={() => {
                                            toggleSidebar();
                                            dispatch<any>(actions.logout({}))
                                        }} style={{ width: '50%' }}
                                    >
                                        Logout
                                    </Button>
                                </div>
                            }
                        >

                        </Drawer>
                    </div>
                </Layout.Header>
            </Layout>
        </nav>
    );
}

export default Navbar;
