import React from 'react';
import { Spin } from 'antd';

function SuspenseLoader() {
  return (
    <div style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }
    }>
      <Spin size="large" tip="Loading..." />
    </div>
  );
}

export default SuspenseLoader;